import React, { FC } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Img from 'gatsby-image';
import { gaButtonEvent } from '../../services/visitor-info';
import { Route } from '../../models/site-links';
import './triple-feature.css';

interface DevFeatureProps {
  firstFeatureIcon: any;
  secondFeatureIcon: any;
  thirdFeatureIcon: any;
  firstHeadingText: string;
  secondHeadingText: string;
  thirdHeadingText: string;
  firstDescriptionText: string;
  secondDescriptionText: string;
  thirdDescriptionText: string;
  firstCTAtext?: string;
  secondCTAtext?: string;
  thirdCTAtext?: string;
  firstCTALink?: Route;
  secondCTALink?: Route;
  thirdCTALink?: Route;
}

const DevFeature: FC<DevFeatureProps> = (props) => {
  return (
    <div className="triple-feature">
      <div className="py-3 triple-feature-container">
        <div className="dev-feature-box">
          <div className="dev-featureicon-box">
            <Img className="dev-feature-icon" fluid={props.firstFeatureIcon} />
          </div>
          <p className="font-heading text-blue-darker triple-feature-title">
            {props.firstHeadingText}
          </p>
          <p className="text-base triple-feature-body">{props.firstDescriptionText}</p>
          {props.firstCTALink && (
            <div className="flex justify-center">
              <AniLink
                to="/"
                onClick={() =>
                  gaButtonEvent(props.firstCTAtext, `Integrations:${props.firstCTAtext}`)
                }
                className="primary-cta-button"
                fadeto={props.firstCTALink}
              >
                {props.firstCTAtext}
              </AniLink>
            </div>
          )}
        </div>
        <div className="dev-feature-box">
          <div className="dev-featureicon-box">
            <Img className="dev-feature-icon" fluid={props.secondFeatureIcon} />
          </div>
          <p className="font-heading text-blue-darker triple-feature-title">
            {props.secondHeadingText}
          </p>
          <p className="text-base triple-feature-body">{props.secondDescriptionText}</p>
          {props.secondCTALink && (
            <div className="flex justify-center">
              <AniLink
                to={props.secondCTALink}
                onClick={() =>
                  gaButtonEvent(props.secondCTAtext, `Integrations:${props.secondCTAtext}`)
                }
                className="primary-cta-button"
                fadeto={props.secondCTALink}
              >
                {props.secondCTAtext}
              </AniLink>
            </div>
          )}
        </div>
        <div className="dev-feature-box">
          <div className="dev-featureicon-box">
            <Img className="dev-feature-icon" fluid={props.thirdFeatureIcon} />
          </div>
          <p className="font-heading text-blue-darker triple-feature-title">
            {props.thirdHeadingText}
          </p>
          <p className="text-base triple-feature-body">{props.thirdDescriptionText}</p>
          {props.thirdCTALink && (
            <div className="flex justify-center">
              <AniLink
                to={props.thirdCTALink}
                onClick={() =>
                  gaButtonEvent(props.thirdCTAtext, `Integrations:${props.thirdCTAtext}`)
                }
                className="primary-cta-button"
                fadeto={props.thirdCTALink}
              >
                {props.thirdCTAtext}
              </AniLink>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DevFeature;
