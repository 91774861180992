// A generic function to get a query parameter by name
const getParameterByName = (name: string, url?: string) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

// The current Google Click Identifier stored in memory variable
let currentGCLID;

// Record the Google Click Identifier from URL query parameter or local storage if previously stored
export const recordGoogleClickIdentifier = () => {
  // Get the Google Click Identifier from a query parameter (I.e. patronscan.com/?gclid=asdadfsdfsdafsad)
  const gclid = getParameterByName('gclid');

  if (gclid) {
    // If exists save to a in memory variable
    currentGCLID = gclid;

    // Also save it to local storage for page refreshes
    localStorage.setItem('temp', gclid);
  } else {
    // Otherwise if a returning visitor or a regular visitor not from an ad
    // Try to load it from local storage if exists
    currentGCLID = localStorage.getItem('temp');
  }
};

// Return the Google Click Identifier if exists
export const getGoogleClickIdentifier = () => currentGCLID;
