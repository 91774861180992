import React, { FC, useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import Axios from 'axios';
import FormButton from '../buttons/form-button';
import { navigate } from 'gatsby';
import { Route } from '../../models/site-links';
import { getGoogleClickIdentifier } from '../../services/google-click-identifier';
import { VisitorData } from '../../services/visitor-info';
import './lead-capture.css';

interface LeadCaptureProps {
  sourcePage: string;
  buttonText: string;
  detailText: string;
  promptText?: string;
  country?: boolean;
  commentPrompt?: string;
}

const LeadCapture: FC<LeadCaptureProps> = (props) => {
  const [gclid, setGCLID] = useState(undefined);
  const [visitId, setVisitId] = useState('undefined');
  const [visitCity, setVisitCity] = useState('undefined');
  const [visitState, setVisitState] = useState('undefined');

  useEffect(() => {
    setTimeout(() => {
      setGCLID(getGoogleClickIdentifier);
      const visitData: VisitorData = JSON.parse(localStorage.getItem('vid'));
      if (visitData) {
        setVisitId(visitData.clientID);
        setVisitCity(visitData.city);
        setVisitState(visitData.state);
      }
    }, 100);
  }, []);

  const { register, handleSubmit } = useForm();
  const submitForm = (data: any) => {
    if (typeof window !== 'undefined') {
      const anyWindow = window as any;
      if (anyWindow.fbq != null) {
        anyWindow.fbq('track', 'Lead');
      }
    }

    const formData = {
      name: data.name,
      organization: data.organization,
      address: data.address,
      email: data.email,
      phone: data.phone,
      your_industry: data.industry,
      country: data.country,
      message: data.message,
      lead_page: `${props.sourcePage}`,
      clientID: data.clientId,
      gclid: data.gclid,
      visitId: data.visitId,
      city: data.city,
      state: data.state,
    };

    Axios.post(
      `https://prod-01.canadacentral.logic.azure.com:443/workflows/844eec674e744991b5309933956c9f36/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=pkYa_Lwa_a8jnpMqdOa3un2ixjx_XI4zd_zvcL0JIts`,
      formData,
    ).then((data: any) => {
      navigate(Route.PricingThanks);
    });
  };

  return (
    <section id="lead-form" className="pt-16 pb-24 bg-blue-orlando flex flex-row justify-center">
      <div className="form-container">
        <div className="capture-prompt text-5xl flex flex-row justify-center">
          {props.promptText}
        </div>
        <div className="mt-2 text-sm flex flex-row text-grey-bluey justify-center">
          {props.detailText}
        </div>
        <form
          className="w-full tab:w-9/12 mt-12 flex flex-column text-grey-bluey"
          onSubmit={handleSubmit(submitForm)}
        >
          <div className="inline-flex flex-wrap w-full px-4 pb-8">
            <label className="block text-sm mb-2" htmlFor="email">
              Email<span className="text-sm text-red">*</span>
            </label>
            <input
              className="block px-4 border-grey-bluey rounded-lg border w-full h-12"
              id="email"
              name="email"
              type="email"
              ref={register({ required: true })}
            />
          </div>
          <div className="inline-flex flex-wrap w-full px-4 pb-8">
            <label className="block text-sm mb-2" htmlFor="Name">
              Name<span className="text-sm text-red">*</span>
            </label>
            <input
              className="block px-4 border-grey-bluey rounded-lg border w-full h-12"
              id="name"
              name="name"
              type="text"
              ref={register({ required: true })}
            />
          </div>
          <div className="inline-flex flex-wrap w-full px-4 pb-8">
            <label className="block text-sm mb-2" htmlFor="Name">
              Business Name
            </label>
            <input
              className="block px-4 border-grey-bluey rounded-lg border w-full h-12"
              id="organization"
              name="organization"
              type="text"
              ref={register({ required: false })}
            />
          </div>
          <div className="inline-flex flex-wrap w-full px-4 pb-8">
            <label className="block text-sm mb-2" htmlFor="Name">
              Phone Number
            </label>
            <input
              className="block px-4 border-grey-bluey rounded-lg border w-full h-12"
              id="phone"
              name="phone"
              type="text"
              ref={register({ required: false })}
            />
          </div>
          {props.country && (
            <div className="inline-flex flex-wrap w-full px-4 pb-8">
              <label className="block text-sm mb-2" htmlFor="Name">
                Country
              </label>
              <input
                className="block px-4 border-grey-bluey rounded-lg border w-full h-12"
                id="country"
                name="country"
                type="text"
                ref={register({ required: false })}
              />
            </div>
          )}
          <div className="inline-flex flex-wrap w-full px-4 pb-2">
            <label className="block text-sm mb-2" htmlFor="message">
              {props.commentPrompt}
            </label>
            <textarea
              className="block px-4 border-grey-bluey rounded-lg border py-2 w-full"
              id="message"
              name="message"
              ref={register({ required: false })}
            ></textarea>
          </div>
          <input
            id="gclid"
            name="gclid"
            value={gclid}
            type="hidden"
            ref={register({ required: false })}
          />
          <input
            id="visitId"
            name="visitId"
            value={visitId}
            type="hidden"
            ref={register({ required: false })}
          />
          <input
            id="city"
            name="city"
            value={visitCity}
            type="hidden"
            ref={register({ required: false })}
          />
          <input
            id="state"
            name="state"
            value={visitState}
            type="hidden"
            ref={register({ required: false })}
          />
          <div className="px-4">
            <FormButton formId={props.buttonText} text={props.buttonText} type="submit" />
            <img
              className="hidden height: 1px width: 1px"
              src="https://px.ads.linkedin.com/collect/?pid=2825404&conversionId=3295700&fmt=gif"
            />
          </div>
        </form>
      </div>
    </section>
  );
};

export default LeadCapture;
