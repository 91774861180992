import React, { FC } from 'react';
import { gaFormEvent } from './../../services/visitor-info';
import './cta-buttons.css';

interface formButtonProps {
  formId: string;
  text: string;
  link?: string;
  className?: string;
  type?: any;
}
const FormButton: FC<formButtonProps> = (props) => {
  return (
    <a target="_blank" href={props.link ? props.link : '#'}>
      <button
        onClick={() => gaFormEvent(props.formId)}
        type={props.type ? props.type : 'button'}
        aria-label={props.text}
        className={props.className ? props.className : 'gg-button bg-blue-gg text-md w-full'}
      >
        {props.text}
      </button>
    </a>
  );
};
export default FormButton;
