import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import LeadCapture from '../components/sections/lead-capture';
import BackgroundImage from 'gatsby-background-image';
import HeroBanner from './../components/sections/hero-banner';
import DevFeature from '../components/sections/dev-feature';
import Buttons from '../components/buttons/feature-buttons';
import { Route } from '../models/site-links';
import './developer.css';

const Developer = () => {
  const data = useStaticQuery(graphql`
    query {
      heroBackground: file(relativePath: { eq: "hero-images/dev-docs-hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heroIcon: file(relativePath: { eq: "icons/dev-hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      IDVerificationIcon: file(relativePath: { eq: "icons/dev_idverification.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      IDBarcodeMRZ: file(relativePath: { eq: "icons/id_mrz_parsing.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cameraScanning: file(relativePath: { eq: "icons/dev_camera_scan.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      accessControlSolutions: file(relativePath: { eq: "icons/dev_access_control.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      KYCScreening: file(relativePath: { eq: "icons/dev_kyc.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hardwareIntegrations: file(relativePath: { eq: "icons/dev_integrations.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ctaBackground: file(relativePath: { eq: "hero-images/dev_CTA_button.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <section id="technology" className="pt-16 min-h-screen flex flex-col justify-center">
      <HeroBanner
        heroImage={data.heroBackground.childImageSharp.fluid}
        featureImage={data.heroIcon.childImageSharp.fluid}
        heading="Simple Integration, Speedy ID Verification"
        subHeading="Catch fake IDs, capture data and verify individuals in seconds. Connect your app today."
        small
        anchorLink="/developer/#dev-lead-form"
        linkText="Get API Docs"
        buttonId="DeveloperHero"
      ></HeroBanner>
      <DevFeature
        firstFeatureIcon={data.IDVerificationIcon.childImageSharp.fluid}
        secondFeatureIcon={data.IDBarcodeMRZ.childImageSharp.fluid}
        thirdFeatureIcon={data.cameraScanning.childImageSharp.fluid}
        firstHeadingText="ID Verification"
        secondHeadingText="ID Barcode/MRZ Parsing"
        thirdHeadingText="Camera Scanning"
        firstDescriptionText="Patronscan’s leading ID verification technology catches most fake IDs in seconds. Our proprietary algorithms go beyond simple ID authentication to look for pixel-level discrepancies present in the modern counterfeit."
        secondDescriptionText="Over 4,600 different IDs are supported by Patronscan’s technology with support for the majority of barcodes and MRZ formats.  Capture date from driver’s licenses, military cards, membership cards, passports and more."
        thirdDescriptionText="Integrate Patronscan’s scanning software into your iOS, Android, web or desktop application to authenticate IDs and capture data. Receive authentication responses in seconds, not hours or days."
      ></DevFeature>
      <DevFeature
        firstFeatureIcon={data.accessControlSolutions.childImageSharp.fluid}
        secondFeatureIcon={data.KYCScreening.childImageSharp.fluid}
        thirdFeatureIcon={data.hardwareIntegrations.childImageSharp.fluid}
        firstHeadingText="Access Control Solutions"
        secondHeadingText="KYC Screening"
        thirdHeadingText="Hardware Integrations"
        firstDescriptionText="Connect our ID scanning technology to controlled entrance points to prevent unauthorized access. Proven to prevent theft by as much as 97.5% while restricting access to age-gated products and services."
        secondDescriptionText="Manage risk and adhere to KYC regulations by checking individuals against databases of known fraudsters and politically exposed individuals."
        thirdDescriptionText="Our ID scanners fit into most solutions. Easily integrate our phyiscal ID scanners into your custom application with just a few lines of code."
      ></DevFeature>
      <a className="anchor" id="dev-lead-form"></a>
      <LeadCapture
        sourcePage="Integrations Page"
        buttonText="Get Started"
        commentPrompt="How would you like to integrate with Patronscan?"
        detailText="Explore the different ways you can integrate with Patronscan. You'll receive documentation momentarily."
        country
      />
      <BackgroundImage
        className="dev-partner-container"
        fluid={data.ctaBackground.childImageSharp.fluid}
      >
        <div className="hire-dev-text flex justify-center">
          Need a development partner to launch your project? Chat with our team today.
        </div>
        <div className="hire-dev-button">
          <Buttons
            devButton
            linkText="Contact Us"
            link={Route.TalkToAnExpert}
            buttonId="DeveloperConnect"
          />
        </div>
      </BackgroundImage>
    </section>
  );
};

export default Developer;
